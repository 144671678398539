
// #region imports

// #region vue
import { Component, Vue, Prop } from 'vue-property-decorator'
// #endregion

// #region interfaces
import {
  ColorPickerPropagationInterface,
  CompanySettingInterface,
  PublicBookingCustomVisualSettingsInterface
} from '@/types/interfaces/setting'
// #endregion

// #region components
import ExpandableContainer from './ExpandableContainer.vue'
import ColorPicker from '@/common/components/ui/card/ColorPicker.vue'
// #endregion

// #region services
import _settingsService from '@/services/setting.service'
import companyService from '@/services/company.service'
// #endregion

// #region utils
import { parseJson } from '@/utils/helper.utils'
import i18n from '@/i18n'
import { defaultsDeep } from 'lodash'
import { addDays } from 'date-fns'
// #endregion

// #region rules
import rules from '@/utils/rules.utils'
// #endregion

// #endregion

@Component({
  name: 'PublicBookingVisualSettings',
  components: {
    ExpandableContainer,
    ColorPicker
  }
})
export default class PublicBookingVisualSettings extends Vue {
  @Prop({ required: true }) readonly companyId!: string
  @Prop({ required: true }) readonly settings!: CompanySettingInterface

  data = {
    active: true,
    key: 'public_booking_custom_visual_settings',
    main_background_color: {
      color: '#ffffffff'
    },
    page_background_color: {
      color: '#ffffffff'
    },
    cards_background_color: {
      color: '#ffffffff'
    },
    cards_border_color: {
      color: '#ffffffff'
    },
    font_color: {
      color: '#000000FF'
    },
    selectors_border_color: {
      color: '#000000FF'
    },
    error_color: {
      color: '#000000FF'
    },
    dates_background_color_past: {
      color: '#000000FF'
    },
    dates_background_color_present: {
      color: '#000000FF'
    },
    dates_background_color_future: {
      color: '#000000FF'
    },
    buttons_background_color: {
      color: '#000000FF'
    },
    buttons_font_color: {
      color: '#000000FF'
    },
    buttons_border_color: {
      color: '#000000FF'
    },
    calendar: {
      color: 'blue',
      dark_mode: false
    }
  } as PublicBookingCustomVisualSettingsInterface

  calendarColorItems = [
    { label: i18n.t('calendar_colors.gray').toString(), value: 'gray' },
    { label: i18n.t('calendar_colors.red').toString(), value: 'red' },
    { label: i18n.t('calendar_colors.orange').toString(), value: 'orange' },
    { label: i18n.t('calendar_colors.yellow').toString(), value: 'yellow' },
    { label: i18n.t('calendar_colors.green').toString(), value: 'green' },
    { label: i18n.t('calendar_colors.teal').toString(), value: 'teal' },
    { label: i18n.t('calendar_colors.blue').toString(), value: 'blue' },
    { label: i18n.t('calendar_colors.indigo').toString(), value: 'indigo' },
    { label: i18n.t('calendar_colors.purple').toString(), value: 'purple' },
    { label: i18n.t('calendar_colors.pink').toString(), value: 'pink' }
  ]

  singleDate = new Date()
  rangeDate = {
    start: addDays(new Date(), 2) as Date,
    end: addDays(new Date(), 7) as Date
  }

  rangeDate2 = {
    start: addDays(new Date(), 1) as Date,
    end: addDays(new Date(), 3) as Date
  }

  // initializers
  rules = rules

  // fields
  previewImage: string | ArrayBuffer | null | undefined = ''
  image: File | null = null
  saveDisabled = true
  deleteDialog = false
  hasUploaded = false

  calendarDarkMode = false

  selectedColor = 'blue'

  test (): void {
    console.log(this.data)
    this.data.calendar.dark_mode = !this.data.calendar.dark_mode
  }

  // #region hooks
  async created (): Promise<void> {
    await this.fetchPublicBookingVisualSettings()
    await this.getBackgroundImage()
  }
  // #endregion

  // #region methods
  handleFileSelected (image: File): void {
    const reader = new FileReader()

    reader.readAsDataURL(image)
    reader.onload = (e) => {
      const img = new Image()

      img.src = e.target?.result as string
      img.onload = () => {
        const imgMaxWidth = 5000
        const imgMaxHeight = 5000
        if (img.width > imgMaxWidth || img.height > imgMaxHeight) {
          const errorMessage = this.$t('settings.logo.file_dimension_limit').toString()
            .replace('{w}', imgMaxWidth.toString())
            .replace('{h}', imgMaxHeight.toString())
          this.$toast.error(errorMessage)
          this.image = null
          this.saveDisabled = true
        } else {
          this.previewImage = e.target?.result
        }
      }
    }

    this.image = image

    this.saveDisabled = false
  }

  async getBackgroundImage (): Promise<void> {
    const imageResult = await companyService.getBackgroundImage(this.companyId)
    console.log(imageResult)

    if (imageResult.filePath) {
      this.previewImage = imageResult.filePath
      this.hasUploaded = true
      this.disableElementOperations()
    }
  }

  async handleDeleteImage (): Promise<void> {
    console.log('delete dialog', this.hasUploaded)
    if (this.hasUploaded) {
      this.deleteDialog = true
    } else {
      this.deleteDialog = false
      this.previewImage = null
      this.image = null
      this.saveDisabled = true
    }
  }

  async colorPickerSubmitEventListener (
    propogationData: ColorPickerPropagationInterface
  ): Promise<void> {
    this.data[propogationData.settings_key].color = propogationData.color

    await this.submit()
  }

  async resetPublicBookingVisualSettings (
    fetchedSettings: CompanySettingInterface | undefined = undefined
  ): Promise<void> {
    const settingsToUse = fetchedSettings || this.settings
    const publicBookingVisualSettings =
      settingsToUse.public_booking_custom_visual_settings

    if (publicBookingVisualSettings) {
      this.data = defaultsDeep(
        {},
        parseJson(publicBookingVisualSettings.toString()),
        this.data
      )
    }
  }

  toggleDeleteDialog (): void {
    this.deleteDialog = !this.deleteDialog
  }

  async toggleActive (): Promise<void> {
    this.data.active = !this.data.active
    this.$nextTick()
    this.submit()
  }

  disableElementOperations () {
    this.saveDisabled = true
    this.deleteDialog = false
  }

  // #endregion

  // #region backend
  async deleteImage (): Promise<void> {
    const deleteResult = await companyService.deleteBackgroundImage(this.companyId)

    if (deleteResult) {
      this.$toast.success(this.$t('settings.public_booking_visual_settings.descriptions.background_image.delete_success').toString())
      this.hasUploaded = false
      this.previewImage = null
      this.image = null
      this.toggleDeleteDialog()
    } else {
      this.$toast.error(this.$t('settings.public_booking_visual_settings.descriptions.background_image.delete_failure').toString())
    }
  }

  async upload (): Promise<void> {
    if (!this.image) return

    const form = this.$refs.form as HTMLFormElement

    const valid = form.validate()

    const formData = new FormData()

    if (valid) {
      formData.append('image', this.image)
      formData.append('company_id', this.companyId)

      await new Promise((resolve, reject) => {
        const imageResult = companyService.uploadBackgroundImage(formData)

        imageResult
          .then((res) => {
            resolve(res)
            this.disableElementOperations()
            this.$toast.success(
              this.$t('settings.public_booking_visual_settings.descriptions.background_image.upload_success').toString()
            )
            this.hasUploaded = true
          })
          .catch((err) => {
            reject(err)
            this.$toast.error(
              this.$t('settings.public_booking_visual_settings.descriptions.background_image.upload_error').toString()
            )
          })
      })
    }
  }

  async fetchPublicBookingVisualSettings (): Promise<void> {
    const settings = await _settingsService.getCompanySettings(this.companyId)

    await this.$nextTick()

    this.resetPublicBookingVisualSettings(settings)
  }

  async submit (): Promise<void> {
    const payload = {
      active: this.data.active,
      key: this.data.key,
      main_background_color: this.data.main_background_color,
      page_background_color: this.data.page_background_color,
      cards_background_color: this.data.cards_background_color,
      cards_border_color: this.data.cards_border_color,
      font_color: this.data.font_color,
      selectors_border_color: this.data.selectors_border_color,
      error_color: this.data.error_color,
      dates_background_color_past: this.data.dates_background_color_past,
      dates_background_color_present: this.data.dates_background_color_present,
      dates_background_color_future: this.data.dates_background_color_future,
      buttons_background_color: this.data.buttons_background_color,
      buttons_font_color: this.data.buttons_font_color,
      buttons_border_color: this.data.buttons_border_color,
      calendar: this.data.calendar
    } as PublicBookingCustomVisualSettingsInterface

    _settingsService.upsertPublicBookingVisualSettings(this.companyId, payload)

    this.$toast.success(
      this.$t('settings.public_booking_visual_settings.success').toString()
    )
  }
  // #endregion

  // #region computed
  get mainTitle (): string {
    return `${this.$t(
      'settings.public_booking_visual_settings.title'
    ).toString()} (${this.data.active
      ? this.$t('settings.public_booking_visual_settings.active').toString()
      : this.$t('settings.public_booking_visual_settings.inactive').toString()
      })`
  }
  // #endregion
}
