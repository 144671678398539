
// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #endregion

// #region services
import BookingService from '@/services/booking.service'
// #endregion

// interfaces
import { BookingInterface } from '@/types/interfaces/booking'

// #region helpers & utils
import { defaultIncoming } from '@/utils/date.utils'
import i18n from '@/i18n'
// #endregion

const DATE_FORMAT = 'd - MMMM - yyyy H:mm'

@Component
export default class BookingDetailsComponent extends Vue {
  @Prop() bookingId!: string

  booking = {} as BookingInterface

  async created (): Promise<void> {
    await this.handleFetchBooking()
  }

  async handleFetchBooking (): Promise<void> {
    if (this.bookingId) {
      try {
        const result = await BookingService.getSingle(this.bookingId)
        this.booking = result
      } catch (err) {
        console.error('Failed to fetch booking:', err)
      }
    }
  }

  closeModal () {
    this.$emit('close-modal')
  }

  get checkedIn (): boolean {
    if (this.booking.guests) {
      return this.booking.guests.length > 0
    }
    return false
  }

  get dateFrom (): string {
    if (!this.booking.date_from) return '---'
    return defaultIncoming(this.booking.date_from, DATE_FORMAT)
  }

  get dateTo (): string {
    if (!this.booking.date_to) return '---'
    return defaultIncoming(this.booking.date_to, DATE_FORMAT)
  }

  get clientName (): string {
    if (this.booking.client) {
      return this.booking.client.name
    }
    return '---'
  }

  get rentingEntities (): string[] {
    if (this.booking.renting_entities) {
      return this.booking.renting_entities.map((entity) => entity.name)
    }
    return []
  }

  get company (): string {
    if (this.booking.company) {
      return this.booking.company.name
    }
    return '---'
  }

  get department (): string {
    if (this.booking.department) {
      return this.booking.department.name
    }
    return '---'
  }

  get isSholeDepartmentReservation (): boolean {
    return this.booking.whole_apartment_reservation || false
  }

  get isManualBooking (): boolean {
    return this.booking.is_manual_booking || false
  }

  get yes (): string {
    return i18n.t('common.yes') as string
  }

  get no (): string {
    return i18n.t('common.no') as string
  }

  get scheduleSystem (): string {
    return this.booking.schedule_system
  }

  get isHourly (): boolean {
    return this.scheduleSystem === 'HOURLY'
  }

  get isDaily (): boolean {
    return this.scheduleSystem === 'DAILY'
  }

  get numberOfInteractors (): number {
    return this.booking.number_of_interactors || 0
  }

  get expectedArrival (): string | null {
    return this.booking.expected_arrival || null
  }

  get expectedDeparture (): string | null {
    return this.booking.expected_departure || null
  }
}
