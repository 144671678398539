
// #region imports

// #region vue
import { Vue, Prop, Component } from 'vue-property-decorator'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
import DefaultTableActions from '@/common/components/ui/table/DefaultTableActions.vue'
import TravelDocumentType from './_TravelDocumentType.vue'
import PurposeOfStay from './_PurposeOfStay.vue'
import Address from './_Address.vue'
import Generic from './_Generic.vue'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region assets
import countryPrefixes from '@/assets/CountryPrefix'
// #endregion

// #region validations
import rules from '@/utils/rules.utils'
// #endregion

// #region interfaces
import {
  InteractorInterface,
  InteractorTableItemInterface
} from '@/types/interfaces/interactors'
import {
  PaginatedApiResponseInterface,
  PaginationInterface
} from '@/types/interfaces/setting'
import { GetRentingEntitiesByIdsResponse } from '@/types/interfaces/renting-entity'
// #endregion

// #region services
import InteractorsService from '@/services/interactors.service'
import BookingService from '@/services/booking.service'
// #endregion

// #endregion

// temp types
type LeanBookingType = {
  client_name: string;
}

@Component({
  components: {
    Table,
    DefaultTableActions
  }
})
export default class ManageGuests extends Vue {
  @Prop({ required: true, default: false }) dialog!: boolean
  @Prop({ required: true }) readonly bookingId!: string
  @Prop({ required: false }) readonly open!: (dialog: boolean) => void
  @Prop({ required: false }) readonly rentingEntityIds!: string[]

  // #region fields
  dialogState = false
  rules = rules
  prefixes = countryPrefixes
  formState = 'ADD'
  deleteDialog = false
  interactor: InteractorInterface | null = null
  valid = false

  currentInteractor = {
    interactor_id: '',
    full_name: '',
    occupation: '',
    address_1: '',
    address_2: '',
    nationality: 'Norway',
    travel_document_type: 'passport',
    travel_document_number: '',
    birthday: '',
    arrival_date: new Date(),
    booking_id: this.bookingId,
    country_of_residence: 'Norway',
    purpose_of_stay: 'holiday',
    renting_entity_id: ''
  } as InteractorInterface

  emptyInteractor = {
    interactor_id: '',
    full_name: '',
    occupation: '',
    address_1: '',
    address_2: '',
    nationality: 'Norway',
    travel_document_type: 'passport',
    travel_document_number: '',
    birthday: '',
    arrival_date: new Date(),
    booking_id: this.bookingId,
    country_of_residence: 'Norway',
    purpose_of_stay: 'holiday',
    renting_entity_id: ''
  } as InteractorInterface

  documentTypes = [
    { label: i18n.t('document_types.passport'), value: 'passport', hint: '' },
    { label: i18n.t('document_types.id'), value: 'id', hint: '' },
    {
      label: i18n.t('document_types.drivers_license'),
      value: 'drivers_license',
      hint: ''
    },
    { label: i18n.t('document_types.other'), value: 'other', hint: '' }
  ]

  purposeOfStay = [
    { label: i18n.t('purpose_of_stay.holiday'), value: 'holiday', hint: '' },
    { label: i18n.t('purpose_of_stay.business'), value: 'business', hint: '' },
    {
      label: i18n.t('purpose_of_stay.course_or_conference'),
      value: 'course_or_conference',
      hint: ''
    }
  ]

  headers = [
    {
      text: i18n.t('table.col.name'),
      value: 'full_name'
    },
    {
      text: i18n.t('table.col.birthday'),
      value: 'birthday'
    },
    {
      text: i18n.t('table.col.occupation'),
      value: 'occupation'
    },
    {
      text: i18n.t('table.col.document_type'),
      value: 'custom_render',
      key: 'travel_document_type',
      render: TravelDocumentType
    },
    {
      text: i18n.t('table.col.document_no'),
      value: 'travel_document_number'
    },
    {
      text: i18n.t('table.col.nationality'),
      value: 'nationality'
    },
    {
      text: i18n.t('table.col.country_of_residence'),
      value: 'country_of_residence'
    },
    {
      text: i18n.t('table.col.purpose_of_stay'),
      value: 'custom_render',
      key: 'purpose_of_stay',
      render: PurposeOfStay
    },
    {
      text: i18n.t('table.col.address_1'),
      value: 'custom_render',
      key: 'address_1',
      render: Address
    },
    {
      text: i18n.t('table.col.renting_entity_name'),
      value: 'custom_render',
      key: 'renting_entity.name',
      render: Generic
    },
    { text: i18n.t('table.col.actions'), value: 'actions', sortable: false }
  ]

  interactors: Array<InteractorInterface> = []
  pagination = {} as PaginationInterface
  rentingEntities = [] as GetRentingEntitiesByIdsResponse[]
  selectedRentingEntity = ''
  loading = false

  // #endregion

  // #region util methods
  getText (data: { name: string; code: string }): string {
    return data.name
  }

  close (): void {
    this.$emit('close')
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.loading = true

    this.interactors = result.interactors.data

    delete result.interactors.data

    this.loading = false
  }
  // #endregion

  // #region table actions
  editItem (row: InteractorTableItemInterface): void {
    this.currentInteractor = { ...this.interactors[row.index] }

    this.formState = 'EDIT'
  }

  cancelEdit (): void {
    this.currentInteractor = { ...this.emptyInteractor }

    this.formState = 'ADD'

    const form = this.$refs.form as HTMLFormElement

    form.reset()
  }

  async deleteItem (): Promise<void> {
    const interactorId =
      this.interactor !== null ? this.interactor.interactor_id : ''

    await InteractorsService.deleteInteractor(this.bookingId, interactorId)

    this.$toast.success(i18n.t('notify.success.departament_deleted').toString())

    this.handleInteractorsFetch()

    this.deleteDialog = false
  }

  initDeleteInteractor (row: InteractorTableItemInterface): void {
    this.deleteDialog = true
    this.interactor = this.interactors[row.index]
  }

  cancelDialog (): void {
    this.deleteDialog = false
    this.interactor = null
  }

  // #endregion

  // #region vue lifecycle methods
  async created (): Promise<void> {
    await this.handleInteractorsFetch()
    await this.fillFirstCustomerAndRentingEntities()
  }
  // #endregion

  // #region backend interaction functions
  async fillFirstCustomerAndRentingEntities (): Promise<void> {
    const bookingResult = await BookingService.getSingle(this.bookingId)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rentingEntities: GetRentingEntitiesByIdsResponse[] = bookingResult.renting_entities.map((entity: any) => ({
      renting_entity_id: entity.id,
      renting_entity_name: entity.name
    }))

    this.rentingEntities = rentingEntities

    if (rentingEntities.length === 1) {
      this.currentInteractor.renting_entity_id = rentingEntities[0].renting_entity_id
    }

    if (this.interactors.length === 0) {
      const bookingResult = await BookingService.getSingle(this.bookingId) as LeanBookingType

      const clientName: string = bookingResult.client_name

      this.currentInteractor.full_name = clientName
    }
  }

  async handleInteractorsFetch (): Promise<void> {
    const result = await InteractorsService.getPaginatedInteractors(
      this.bookingId
    )
    this.setData(result)
  }

  async create (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement

    const valid = form.validate()

    if (valid) {
      const interactor = {
        ...this.currentInteractor,
        booking_id: this.bookingId
      }

      await InteractorsService.create(this.bookingId, interactor)

      this.$toast.success(
        i18n.t('notify.success.guest_added_successfully').toString()
      )

      form.reset()

      await this.handleInteractorsFetch()
      // set the renting entity to the first one if there is only one by default
      if (this.rentingEntities.length === 1) {
        this.currentInteractor.renting_entity_id = this.rentingEntities[0].renting_entity_id
      }
    }
  }

  async update (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement

    const valid = form.validate()

    if (valid) {
      const interactor = {
        ...this.currentInteractor
      }

      await InteractorsService.update(
        this.bookingId,
        interactor.interactor_id,
        interactor
      )

      this.$toast.success(
        i18n.t('notify.success.guest_updated_successfully').toString()
      )

      this.formState = 'ADD'

      this.handleInteractorsFetch()
      form.reset()
    }
  }
  // #endregion

  // #region pagination
  async nextPage (): Promise<void> {
    const result = await InteractorsService.goToPage(
      this.pagination.next_page_url
    )
    this.setData(result)
  }

  async prevPage (): Promise<void> {
    const result = await InteractorsService.goToPage(
      this.pagination.prev_page_url
    )
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    const result = await InteractorsService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }
  // #endregion
}
