
// #region imports

// vue
import { Component, Vue, Prop } from 'vue-property-decorator'

// interfaces
import { CompanySettingInterface } from '@/types/interfaces/setting'

// components
import ExpandableContainer from './ExpandableContainer.vue'

// validations
import rules from '@/utils/rules.utils'

// #region services
import _settingService from '@/services/setting.service'
import { parseJson } from '@/utils/helper.utils'
// #endregion

// #endregion

@Component({
  name: 'CompanyWebsiteUrlSettings',
  components: {
    ExpandableContainer
  }
})
export default class CompanyWebsiteUrlSettings extends Vue {
  @Prop({ required: true }) readonly companyId!: string
  @Prop({ required: true }) readonly settings!: CompanySettingInterface

  // fields - start
  formValid = false
  url = null as null | string
  originalCompanyWebsiteUrl: string | null = null
  // fields - end

  // injections - start
  rules = rules
  // injections - end

  // methods - start

  async resetCompanyWebsiteUrlSettings (
    fetchedSettings: CompanySettingInterface | undefined = undefined
  ): Promise<void> {
    const settingsToUse = fetchedSettings || this.settings
    const companyWebsiteUrl =
      settingsToUse.company_website_url

    if (companyWebsiteUrl) {
      const parsed = parseJson(companyWebsiteUrl.toString())
      this.url = parsed
      this.originalCompanyWebsiteUrl = parsed
    } else {
      this.originalCompanyWebsiteUrl = null
      this.resetAll()
    }
  }

  resetForm (formName: string): void {
    const form = this.$refs[formName] as HTMLFormElement
    if (form) {
      form.reset()
      form.resetValidation()
    }
  }

  resetData (): void {
    this.url = ''
  }

  resetAll (): void {
    this.resetForm('form')
    this.resetData()
  }

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()

    if (valid && this.url) {
      try {
        await _settingService.upsertAnySetting(
          this.companyId,
          'company_website_url',
          this.url
        )

        this.$toast.success(
          this.$t(
            'component.company_website_url.url_saved_successfully'
          ).toString()
        )

        this.originalCompanyWebsiteUrl = this.url
        await this.fetchWebsiteUrl()
      } catch (error) {
        console.error(error)
        this.$toast.error(
          this.$t(
            'component.company_website_url.url_save_failure'
          ).toString()
        )
      }
    }
  }

  async deleteCompanyWebsiteUrl (): Promise<void> {
    try {
      await _settingService.deleteCompanySettingByKey(
        this.companyId,
        'company_website_url'
      )

      this.$toast.success(
        this.$t(
          'component.company_website_url.url_deleted_successfully'
        ).toString()
      )

      this.originalCompanyWebsiteUrl = null
      await this.fetchWebsiteUrl()
    } catch (error) {
      this.$toast.error(
        this.$t(
          'component.company_website_url.url_delete_failure'
        ).toString()
      )
    }

    this.resetAll()
  }

  validateForm (): void {
    const form = this.$refs.form as Vue & { validate: () => boolean }
    if (form) {
      this.formValid = form.validate()
    }
  }

  async fetchWebsiteUrl (): Promise<void> {
    const fetchedSettings = await _settingService.getCompanySettings(
      this.companyId
    )

    await this.$nextTick()

    await this.resetCompanyWebsiteUrlSettings(fetchedSettings)
  }

  // methods - end

  // vue hooks - start
  async created (): Promise<void> {
    await this.fetchWebsiteUrl()
  }

  // vue hooks - end

  // computed - start
  get companyWebsiteUrl (): string | null {
    return this.url
  }

  get canDeleteCompanyWebsiteUrl (): boolean {
    return !!this.originalCompanyWebsiteUrl
  }
}
