var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.bookingData)?_c('v-card',{class:`tw-w-full ${
    _vm.canIApplyVisualSettings ? 'gFontColor--text gMainBackgroundColor' : ''
  }`,attrs:{"flat":""}},[_c('v-card-text',{staticClass:"tw-flex tw-flex-col tw-justify-center tw-items-center",attrs:{"flat":""}},[_c('v-card-title',{class:`justify-center ${
        _vm.canIApplyVisualSettings ? 'gFontColor--text' : 'tw-text-emerald-500'
      } tw-text-2xl tw-uppercase`},[_vm._v(" "+_vm._s(_vm.$t('common.thank_you'))+" ")]),_c('p',{class:`${
        _vm.canIApplyVisualSettings ? 'gFontColor--text' : 'tw-text-slate-600'
      } tw-py-2 tw-mt-2 tw-text-lg tw-uppercase`},[_vm._v(" "+_vm._s(_vm.$t('common.booking_reservation_succeeded'))+" ")]),_c('v-divider',{class:_vm.canIApplyVisualSettings ? 'gGeneralElementsBackgroundColor' : ''}),_c('v-card-subtitle',{class:`justify-center ${
        _vm.canIApplyVisualSettings ? 'gFontColor--text' : ''
      }`},[_vm._v(" "+_vm._s(_vm.$t('card.subtitle.booking_details'))+" ")])],1),_c('v-divider',{class:_vm.canIApplyVisualSettings ? 'gGeneralElementsBackgroundColor' : ''}),_c('v-card-text',{class:_vm.canIApplyVisualSettings ? 'gFontColor--text' : ''},[_c('v-card',{class:`my-2 d-flex flex-column ${
        _vm.canIApplyVisualSettings ? 'gMainBackgroundColor gFontColor--text' : ''
      }`,attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.bookingData.client.name))]),_c('div',{staticClass:"tw-flex tw-flex-col tw-justify-between md:tw-flex-row"},[_c('div',[_c('v-card-subtitle',{staticClass:"py-0 tw-font-bold"},[_vm._v(" "+_vm._s(_vm.clientPhoneNumber)+" ")]),_c('v-card-subtitle',{staticClass:"py-0 tw-font-bold"},[_vm._v(" "+_vm._s(_vm.bookingData.client.email)+" ")])],1),_c('div',{staticClass:"my-auto tw-font-bold tw-mt-2 tw-pt-2 tw-px-4"},[(_vm.bookingData.department.schedule_system === 'HOURLY')?[_c('v-chip',{class:_vm.canIApplyVisualSettings
                  ? 'gCardBackgroundColor gFontColor--text'
                  : '',attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.dateRange)+" ")])]:_vm._e(),(_vm.bookingData.department.schedule_system === 'DAILY')?[_c('v-chip',{class:_vm.canIApplyVisualSettings
                  ? 'gCardBackgroundColor gFontColor--text'
                  : '',attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.dateRange)+" ")]),(_vm.bookingData.expected_arrival)?_c('v-chip',{class:`tw-p-2 tw-rounded-lg  tw-flex tw-gap-2 tw-items-center tw-justify-center tw-text-sm tw-mt-3 ${
                _vm.canIApplyVisualSettings
                  ? 'gCardBackgroundColor gFontColor--text'
                  : 'tw-bg-neutral-700 tw-text-white'
              }`,attrs:{"label":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('component.book.expected_arrival'))+": "+_vm._s(_vm.bookingData.expected_arrival))])]):_vm._e(),(_vm.bookingData.expected_departure)?_c('div',{class:`tw-p-2 tw-rounded-lg  tw-flex tw-gap-2 tw-items-center tw-justify-center tw-text-sm tw-mt-3 ${
                _vm.canIApplyVisualSettings
                  ? 'gCardBackgroundColor gFontColor--text'
                  : 'tw-bg-neutral-700 tw-text-white'
              }`},[_c('span',[_vm._v(_vm._s(_vm.$t('component.book.expected_departure'))+": "+_vm._s(_vm.bookingData.expected_departure))])]):_vm._e()]:_vm._e()],2)])],1),_c('v-divider',{class:_vm.canIApplyVisualSettings ? 'gGeneralElementsBackgroundColor' : ''}),_c('v-card',{class:`tw-my-2 d-flex flex-column ${
        _vm.canIApplyVisualSettings ? 'gMainBackgroundColor gFontColor--text' : ''
      }`,attrs:{"flat":""}},[_c('v-card-title',{staticClass:"tw-flex tw-justify-between"},[_c('div',[_vm._v(" "+_vm._s(_vm.bookingData.company.name)+" ")]),(_vm.bookingData.company.logo_url)?_c('v-img',{attrs:{"src":_vm.bookingData.company.logo_url,"alt":_vm.bookingData.company.name,"lazy-src":_vm.bookingData.company.logo_url,"max-width":"150","max-height":"30"}}):_vm._e()],1),_c('div',{staticClass:"d-flex justify-space-between flex-column flex-md-row tw-font-bold"},[_c('v-card-subtitle',{staticClass:"py-0"},[_vm._v(" "+_vm._s(_vm.bookingData.department.name)+" ")]),_c('v-card-subtitle',{staticClass:"py-0"},[_vm._v(" "+_vm._s(_vm.departmentAddress)+" ")])],1)],1),_c('v-divider',{class:_vm.canIApplyVisualSettings ? 'gGeneralElementsBackgroundColor' : ''}),_c('v-card-title',[_vm._v(_vm._s(_vm.$t('card.title.note')))]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.notes.value ? _vm.notes.value : 'N.A')+" ")]),_c('v-divider',{class:_vm.canIApplyVisualSettings ? 'gGeneralElementsBackgroundColor' : ''}),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$t('card.subtitle.details')))]),(_vm.tags.value)?_c('div',{staticClass:"d-flex flex-row gap-2 tw-my-2 tw-text-end"},[_c('h4',{class:`tw-uppercase tw-font-extrabold tw-underline tw-underline-offset-4 tw-decoration-2 ${
          _vm.canIApplyVisualSettings ? '' : 'tw-decoration-sky-500'
        }`},[_vm._v(" "+_vm._s(_vm.tags.key.toUpperCase())+": ")]),_vm._l((_vm.parseJson(_vm.tags.value)),function(item,indx){return _c('v-chip',{key:indx,staticClass:"tw-font-bold",attrs:{"color":_vm.canIApplyVisualSettings
            ? 'gCardBackgroundColor gFontColor--text'
            : 'info',"outlined":!_vm.canIApplyVisualSettings,"small":""}},[_vm._v(" "+_vm._s(item)+" ")])})],2):_vm._e()],1),_c('v-card-text',{staticClass:"tw-flex tw-justify-between tw-mb-4"},[(_vm.companyWebsiteUrlSetting)?_c('v-btn',{attrs:{"href":_vm.normalizedCompanyWebsiteUrl,"color":`${
        _vm.canIApplyVisualSettings
          ? 'gButtonBackgroundColor gButtonFontColor--text'
          : 'error'
      }`},on:{"click":_vm.putIntoLoading}},[_vm._v(" "+_vm._s(_vm.$t('button.home'))+" ")]):_vm._e(),_c('v-btn',{attrs:{"color":`${
        _vm.canIApplyVisualSettings
          ? 'gButtonBackgroundColor gButtonFontColor--text'
          : 'primary'
      }`},on:{"click":function($event){return _vm.$emit('newBooking')}}},[_vm._v(" "+_vm._s(_vm.$t('button.new_reservation'))+" ")])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }